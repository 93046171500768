import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material'
import {
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import { useState } from 'react'

import { DiscountTargetType, DiscountType, MetaCouponModel } from '@/apis/coupon/coupon.api'
import MetaCouponForm from '@/components/coupon/MetaCouponForm'
import { useMetaCoupons } from '@/hooks/coupon/coupon.hook'

const CouponMainPage = () => {
  const { systemMetaCoupons, userMetaCoupons, isLoading, createMeta, updateMeta, deleteMeta } = useMetaCoupons()
  const [selectedMeta, setSelectedMeta] = useState<MetaCouponModel | null>(null)
  const [isFormOpen, setIsFormOpen] = useState(false)

  const handleCreate = () => {
    setSelectedMeta(null)
    setIsFormOpen(true)
  }

  const handleEdit = (meta: MetaCouponModel) => {
    setSelectedMeta(meta)
    setIsFormOpen(true)
  }

  const handleDelete = (id: number) => {
    if (window.confirm('정말 삭제하시겠습니까?')) {
      deleteMeta(id)
    }
  }

  const handleSubmit = (values: any) => {
    if (selectedMeta) {
      updateMeta({ id: selectedMeta.id, data: values })
    } else {
      createMeta(values)
    }
    setIsFormOpen(false)
  }

  const renderMetaCouponTable = (metaCoupons: MetaCouponModel[], title: string) => (
    <Card sx={{ mt: 2, p: 2 }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mb={2}>
        <Typography variant="h6">{title}</Typography>
        <Button
          variant="contained"
          onClick={handleCreate}>
          새 쿠폰 메타 생성
        </Button>
      </Stack>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>이름</TableCell>
              <TableCell>설명</TableCell>
              <TableCell>할인 유형</TableCell>
              <TableCell>할인 대상</TableCell>
              <TableCell>할인 값</TableCell>
              <TableCell>최대 할인 금액</TableCell>
              <TableCell>유효 기간(일)</TableCell>
              <TableCell>발급 가능 수</TableCell>
              <TableCell>액션</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {metaCoupons.map((meta) => (
              <TableRow key={meta.id}>
                <TableCell>{meta.name}</TableCell>
                <TableCell>{meta.description}</TableCell>
                <TableCell>{meta.discountType === DiscountType.PERCENTAGE ? '비율' : '정액'}</TableCell>
                <TableCell>{meta.discountTargetType}</TableCell>
                <TableCell>{meta.discountValue}{meta.discountType === DiscountType.PERCENTAGE ? '%' : '원'}</TableCell>
                <TableCell>{meta.maxDiscountAmount}원</TableCell>
                <TableCell>{meta.duration}일</TableCell>
                <TableCell>{meta.totalIssueCount}</TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => handleEdit(meta)}
                    size="small">
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => handleDelete(meta.id)}
                    size="small"
                    color="error">
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  )

  if (isLoading) {
    return <div>Loading...</div>
  }

  return (
    <div>
      {renderMetaCouponTable(systemMetaCoupons, 'System Meta Coupon')}
      {renderMetaCouponTable(userMetaCoupons, 'User Meta Coupon')}

      <Dialog
        open={isFormOpen}
        onClose={() => setIsFormOpen(false)}
        maxWidth="md"
        fullWidth>
        <DialogTitle>{selectedMeta ? '쿠폰 메타 수정' : '새 쿠폰 메타 생성'}</DialogTitle>
        <DialogContent>
          <MetaCouponForm
            initialValues={selectedMeta || {
              name: '',
              description: '',
              discountType: DiscountType.PERCENTAGE,
              discountTargetType: DiscountTargetType.ALL,
              discountValue: 0,
              maxDiscountAmount: 0,
              duration: 30,
              totalIssueCount: 0
            }}
            onSubmit={handleSubmit}
            onCancel={() => setIsFormOpen(false)}
          />
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default CouponMainPage
