import { Methods, request } from '@/libs/request'
import { ROUTER } from '@/router'

const { SURVEY_USER_NEED_TO_RESPOND } = ROUTER

export type SurveyUserModel = {
  id: number;
  userId: number;
  email: string;
  name: string;
  isResponded: boolean;
  isCompleted: boolean;
  isActive: boolean;
  allocatedAt: string;
}

export const getSurveyUsers = async (surveyId: number) => {
  const url = `${SURVEY_USER_NEED_TO_RESPOND}/${surveyId}/users`
  console.log('Request URL:', url)
  return request<SurveyUserModel[]>({
    url,
    method: Methods.GET
  })
}