import { Methods, request } from '@/libs/request'

export enum DiscountType {
  PERCENTAGE = 'PERCENTAGE',
  FIXED = 'FIXED'
}

export enum DiscountTargetType {
  ALL = 'ALL',
  SHOP = 'SHOP',
  TEATOGATHER = 'TEATOGATHER',
  PRODUCT = 'PRODUCT'
}

export type MetaCouponModel = {
  id: number;
  name: string;
  description: string;
  discountType: DiscountType;
  discountTargetType: DiscountTargetType;
  discountValue: number;
  maxDiscountAmount: number;
  duration: number;
  totalIssueCount: number;
  isActive: boolean;
  teaTogetherIds?: number[];
  shopIds?: number[];
  productIds?: number[];
}

export type MetaCouponResponseType = MetaCouponModel[]

export type CreateMetaCouponRequest = Omit<MetaCouponModel, 'id' | 'isActive'>
export type UpdateMetaCouponRequest = Partial<CreateMetaCouponRequest>

export const getUserMetaCoupons = () => {
  return request<MetaCouponResponseType>({
    baseURL: process.env.REACT_APP_SERVER_ENDPOINT,
    url: '/admin-coupon/meta/user',
    method: Methods.GET
  })
}

export const getSystemMetaCoupons = () => {
  return request<MetaCouponResponseType>({
    baseURL: process.env.REACT_APP_SERVER_ENDPOINT,
    url: '/admin-coupon/meta/system',
    method: Methods.GET
  })
}

export const createMetaCoupon = (data: CreateMetaCouponRequest) => {
  return request<MetaCouponModel>({
    baseURL: process.env.REACT_APP_SERVER_ENDPOINT,
    url: '/admin-coupon/meta',
    method: Methods.POST,
    data
  })
}

export const updateMetaCoupon = (id: number, data: UpdateMetaCouponRequest) => {
  return request<MetaCouponModel>({
    baseURL: process.env.REACT_APP_SERVER_ENDPOINT,
    url: `/admin-coupon/meta/${id}`,
    method: Methods.PATCH,
    data
  })
}

export const deleteMetaCoupon = (id: number) => {
  return request<void>({
    baseURL: process.env.REACT_APP_SERVER_ENDPOINT,
    url: `/admin-coupon/meta/${id}`,
    method: Methods.DELETE
  })
}

