import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField
} from '@mui/material'
import { Controller, useForm } from 'react-hook-form'

import { DiscountTargetType, DiscountType, MetaCouponModel } from '@/apis/coupon/coupon.api'

type MetaCouponFormProps = {
  initialValues: Partial<MetaCouponModel>;
  onSubmit: (values: any) => void;
  onCancel: () => void;
}

const MetaCouponForm = ({ initialValues, onSubmit, onCancel }: MetaCouponFormProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({ defaultValues: initialValues })

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid
        container
        spacing={2}
        sx={{ mt: 1 }}>
        <Grid
          item
          xs={12}>
          <Controller
            name="name"
            control={control}
            rules={{ required: '이름은 필수입니다' }}
            render={({ field }) => (
              <TextField
                {...field}
                label="이름"
                fullWidth
                error={!!errors.name}
                helperText={errors.name?.message}
              />
            )}
          />
        </Grid>

        <Grid
          item
          xs={12}>
          <Controller
            name="description"
            control={control}
            rules={{ required: '설명은 필수입니다' }}
            render={({ field }) => (
              <TextField
                {...field}
                label="설명"
                fullWidth
                multiline
                rows={3}
                error={!!errors.description}
                helperText={errors.description?.message}
              />
            )}
          />
        </Grid>

        <Grid
          item
          xs={6}>
          <Controller
            name="discountType"
            control={control}
            rules={{ required: '할인 유형은 필수입니다' }}
            render={({ field }) => (
              <FormControl
                fullWidth
                error={!!errors.discountType}>
                <InputLabel>할인 유형</InputLabel>
                <Select
                  {...field}
                  label="할인 유형">
                  <MenuItem value={DiscountType.PERCENTAGE}>비율 할인</MenuItem>
                  <MenuItem value={DiscountType.FIXED}>정액 할인</MenuItem>
                </Select>
                {errors.discountType && (
                  <FormHelperText>{errors.discountType.message}</FormHelperText>
                )}
              </FormControl>
            )}
          />
        </Grid>

        <Grid
          item
          xs={6}>
          <Controller
            name="discountTargetType"
            control={control}
            rules={{ required: '할인 대상은 필수입니다' }}
            render={({ field }) => (
              <FormControl
                fullWidth
                error={!!errors.discountTargetType}>
                <InputLabel>할인 대상</InputLabel>
                <Select
                  {...field}
                  label="할인 대상">
                  <MenuItem value={DiscountTargetType.ALL}>전체</MenuItem>
                  <MenuItem value={DiscountTargetType.SHOP}>상점</MenuItem>
                  <MenuItem value={DiscountTargetType.TEATOGATHER}>티투게더</MenuItem>
                  <MenuItem value={DiscountTargetType.PRODUCT}>상품</MenuItem>
                </Select>
                {errors.discountTargetType && (
                  <FormHelperText>{errors.discountTargetType.message}</FormHelperText>
                )}
              </FormControl>
            )}
          />
        </Grid>

        <Grid
          item
          xs={6}>
          <Controller
            name="discountValue"
            control={control}
            rules={{
              required: '할인 값은 필수입니다',
              min: { value: 0, message: '0 이상이어야 합니다' }
            }}
            render={({ field }) => (
              <TextField
                {...field}
                type="number"
                label="할인 값"
                fullWidth
                error={!!errors.discountValue}
                helperText={errors.discountValue?.message}
              />
            )}
          />
        </Grid>

        <Grid
          item
          xs={6}>
          <Controller
            name="maxDiscountAmount"
            control={control}
            rules={{
              required: '최대 할인 금액은 필수입니다',
              min: { value: 0, message: '0 이상이어야 합니다' }
            }}
            render={({ field }) => (
              <TextField
                {...field}
                type="number"
                label="최대 할인 금액"
                fullWidth
                error={!!errors.maxDiscountAmount}
                helperText={errors.maxDiscountAmount?.message}
              />
            )}
          />
        </Grid>

        <Grid
          item
          xs={6}>
          <Controller
            name="duration"
            control={control}
            rules={{
              required: '유효 기간은 필수입니다',
              min: { value: 1, message: '1일 이상이어야 합니다' }
            }}
            render={({ field }) => (
              <TextField
                {...field}
                type="number"
                label="유효 기간 (일)"
                fullWidth
                error={!!errors.duration}
                helperText={errors.duration?.message}
              />
            )}
          />
        </Grid>

        <Grid
          item
          xs={6}>
          <Controller
            name="totalIssueCount"
            control={control}
            rules={{
              required: '발급 가능 수는 필수입니다',
              min: { value: 0, message: '0 이상이어야 합니다' }
            }}
            render={({ field }) => (
              <TextField
                {...field}
                type="number"
                label="발급 가능 수"
                fullWidth
                error={!!errors.totalIssueCount}
                helperText={errors.totalIssueCount?.message}
              />
            )}
          />
        </Grid>
      </Grid>

      <Stack
        direction="row"
        spacing={2}
        justifyContent="flex-end"
        sx={{ mt: 3 }}>
        <Button
          variant="outlined"
          onClick={onCancel}>
          취소
        </Button>
        <Button
          type="submit"
          variant="contained">
          저장
        </Button>
      </Stack>
    </form>
  )
}

export default MetaCouponForm