import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import {
  createMetaCoupon,
  CreateMetaCouponRequest,
  deleteMetaCoupon,
  getSystemMetaCoupons,
  getUserMetaCoupons,
  updateMetaCoupon,
  UpdateMetaCouponRequest
} from '@/apis/coupon/coupon.api'

export const useMetaCoupons = () => {
  const queryClient = useQueryClient()

  const { data: systemMetaCoupons = [], isLoading: isLoadingSystem } = useQuery({
    queryKey: ['meta-coupons', 'system'],
    queryFn: getSystemMetaCoupons
  })

  const { data: userMetaCoupons = [], isLoading: isLoadingUser } = useQuery({
    queryKey: ['meta-coupons', 'user'],
    queryFn: getUserMetaCoupons
  })

  const { mutate: createMeta } = useMutation({
    mutationFn: (data: CreateMetaCouponRequest) => createMetaCoupon(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['meta-coupons'] })
    }
  })

  const { mutate: updateMeta } = useMutation({
    mutationFn: ({ id, data }: { id: number; data: UpdateMetaCouponRequest }) => updateMetaCoupon(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['meta-coupons'] })
    }
  })

  const { mutate: deleteMeta } = useMutation({
    mutationFn: (id: number) => deleteMetaCoupon(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['meta-coupons'] })
    }
  })

  return {
    systemMetaCoupons,
    userMetaCoupons,
    isLoading: isLoadingSystem || isLoadingUser,
    createMeta,
    updateMeta,
    deleteMeta
  }
}
