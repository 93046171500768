export const ROUTER = {
  MAIN: '/',
  /* 테스트 버전 */
  TEA: '/admin-tea',
  TEAHOUSE: '/teahouse',
  POST: '/admin-post',
  TEATOGETHER: '/tea-together',
  NOTIFICATION: '/notification',
  TASTINGNOTE: '/tasting-note',
  SHOPPING: '/shopping',
  FESTIVAL: '/festival',
  USER: '/user',
  COUPON: '/coupon',
  ACTIVITY_LOG: '/activity-log',
  SURVEY: '/survey',
  SURVEY_QUESTIONS: '/survey-questions',
  SURVEY_QUESTION_OPTIONS: '/survey-question-options',
  SURVEY_USER_NEED_TO_RESPOND: '/survey-user-need-to-respond',
  //Admin
  FESTIVAL_ADMIN: '/festival-admin',
  SHOPPING_ADMIN: '/shopping-admin',
  UPLOAD_ADMIN: '/upload-admin'
} as const
